.scroll-arrow {
  position: fixed;
  width: 100%;
  bottom: 65px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  color: #cc9933;
}

.scroll-arrow:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}