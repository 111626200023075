.about {
  font-family: TiemposFine;
  font-weight: 300;
  text-align: left;
  background: #071014;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 30% 1fr;
  justify-items: start;
  align-items: center;
  padding: 0 45px;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
}
.about a {
  color: #fff;
  text-decoration: none;
}
.about a:hover,
.about a:active,
.about a.active {
  color: #cc9933;
}

.hello {
  display: grid;
  align-self: start;
  font-size: 4rem;
}

.intro {
  display: grid;
  align-self: center;
  grid-column: 1;
  grid-row-start: 2;
  font-size: 1.8rem;
  overflow-wrap: break-word;
}

.image-container {
  grid-column: 2 / -1;
  grid-row: 1 / -1;
  align-self: start;
}

.profile {
  width: 100%;
}

.social {
  display: inline-flex;
  justify-content: flex-start;
  font-size: 1.6rem;
}
.social ul {
  display: inline-flex;
  list-style: none;
  padding-left: 10px;
}
.social li {
  padding-right: 10px;
}

@media screen and (max-width: 599px) {
  .about {
    display: block;
    padding-bottom: 45px;
  }
  .image-container {
    display: none;
  }
  .intro {
    display: flex;
    flex-direction: column;
  }
  .social {
    display: flex;
    flex-direction: row;
  }
}
