.projects {
  background: #EAEAEA;
  font-family: NeueHaasUnicaPro;
  font-weight: 300;
  display: grid;
  grid-gap: 5px;
  grid-template-rows: 1fr;
  padding-top: 50px;
}

.projects a:hover,
.projects a:active,
.projects a.active {
  color: #908434;
}

.projects strong {
  font-size: 1.1em;
  color: #071014;
}

/* Even Numbered Projects */
.project-even {
  background: #D8D8D8;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 1fr 2fr 1fr;
  color: #071C2C;
  padding: 0 80px;
  webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
}

.project-even h3 {
  font-family: TiemposFine;
  font-weight: 400;
  font-size: 3em;
  grid-column: 1;
  align-self: center;
}
.project-even p {
  font-size: 1.5em;
  line-height: 1.25;
  grid-row: 2;
  align-self: start;
}
.project-even a {
  text-decoration: underline;
  color: #33334d;
  font-size: 1em;
  font-weight: 400;
  grid-row: 3;
}
.project-even iframe {
  grid-column: 2;
  grid-row: 1 / 3;
  justify-self: end;
  align-self: center;
  margin-top: 100px;
  margin-right: 40px;
  border: 1px solid black;
  background-color: rgb(0, 0, 0);
}
/* Note: no styling for img tag */

/* Odd Numbered Projects */
.project-odd {
  background: #EAEAEA;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  color: #071C2C;
  padding: 0 80px;
  webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
}

.project-odd h3 {
  font-family: TiemposFine;
  font-weight: 400;
  font-size: 3em;
  grid-column: 2;
  align-self: center;
}
.project-odd p {
  font-size: 1.5em;
  line-height: 1.25;
  grid-row: 2;
  align-self: center;
}
.project-odd a {
  text-decoration: underline;
  color: #33334d;
  font-size: 1em;
  font-weight: 400;
  grid-column: 2;
  grid-row: 3;
}
.project-odd img {
  padding-top: 20px;
  width: 70%;
  grid-column: 1;
  grid-row: 1 / 3;
  justify-self: start;
  align-self: center;
}
.project-odd iframe {
  padding-top: 20px;
  grid-column: 1;
  grid-row: 1 / 3;
  justify-self: start;
  align-self: center;
  border: 1px solid black;
  background-color: rgb(0, 0, 0);
}

@media screen and (max-width: 599px) {
  .projects {
    display: block;
    /* padding: 0 40px; */
    margin: 0 auto;
  }
  .project-odd {
    display: block;
    font-size: 1.15em;
    padding: 20px;
    clip-path: none;
  }
  .project-even {
    display: block;
    font-size: 1.15em;
    padding: 20px;
    clip-path: none;
  }
  .project-odd iframe {
    padding-top: 20px;
    width: 100%;
  }
  .project-even iframe {
    padding-top: 20px;
    width: 100%;
  }
}
