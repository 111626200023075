.blog-post {
  font-family: TiemposFine;
  font-weight: 300;
  font-size: 1.25em;
  padding: 20px;
}

.blog-post h2 {
  font-weight: 400;
}

.blog-post h2:after {
  content: "";
  display: block;
  padding-top: 20px;
  border-bottom: 6px solid white;
  width: 80%;
}

.blog-paragraph {
  font-size: 1em;
  line-height: 1.25;
}

.blog-post a {
  font-family: NeueHaasUnicaPro;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  text-decoration: underline;
}

.blog-post a:hover,
.blog-post a:active,
.blog-post a.active {
  color: #cc9933;
}

.blog-post img {
  height: auto;
  max-height: 250px;
  width: auto;
  max-width: 250px;
}
