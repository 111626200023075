.app {
  background: #EAEAEA;
  /* padding-top: 10px; */
}

@media screen and (max-width: 599px) {
  .app {
    padding-top: 0px;
    width: 100vw;
  }
}
