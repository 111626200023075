.blogs-container {
  background-color: #071C2C;
  padding-top: 50px;
  list-style: none;
  height: 84vh;
}

.blogs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  padding: 10px;
}

@media screen and (max-width: 599px) {
  .blogs-container {
    display: block;
    height: 100%;
  }
  .blogs {
    display: block;
    font-size: 1.15em;
  }
}
